@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Poppins:wght@400;700&display=swap");

:root {
    --mainColor:#000;
    --secondColor:#fff;
    --LinkColor:#767676;
}

html {
    scroll-behavior: smooth !important;
    scroll-padding-top: 1rem;
}

main {
    min-height: calc(100vh - 150px);
}

.navbar {
    .container {
        a {
            color: var(--LinkColor);
            transition: all 250ms ease-in-out;
            &:hover {
                color: var(--mainColor);
            }
        }
    }
}

.hero {
    background-image: url("./images/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: calc(100vh - 150px);
    position: relative;
    .header-content {
        h1 {
            color: #fff !important;
            font-size: 2rem;
            font-weight: 700;
        }
        p {
            color: var(--secondColor);
            font-size: 1.25rem;
        }
    }
}
.overlay {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #00000072;
    width: 100%;
    height: 100%;
}

.about-section {
    h2 {
        color: var(--mainColor);
    }
    p {
        color: var(--LinkColor);
    }
}

